<style lang="scss">
.technische-daten {
  .tabelle-box {
    [class*='col-'] {
        justify-content: center;
        align-self:center;
    }

  }

  .kontakt-box-all {
    h4 {
      font-size:120%;
      margin-top:15px;
      font-family: 'KleineSans-Bold';
      
    }
    p {
      margin:0;
      margin-bottom:5px;
    }
    .img-cope {
      max-width:200px;
      display:block;
      margin:15px 0;
    }
  }
  
    
}
</style>

<template>
  <div class="technische-daten content container">
    <h1>Technische Daten</h1>

    <p>Damit eine Übernahme Ihrer Daten problemlos funktioniert, finden Sie
nachfolgend einige technische Richtlinien für den Zeitungs- und Magazindruck.
Ein Tipp: Um nicht in Terminnot zu geraten, fragen Sie bei Unklarheiten lieber
gleich einen unserer
Spezialisten – sie helfen Ihnen gerne weiter.</p>
<p>Bitte geben Sie Ihren Ordnern, PDF-Dateien und Ihrem E-Mail-Betreff einen aussagekräftigen Namen
(z. B. Musterfirma-31-01-2022.pdf) mit <strong>Firmennamen</strong> und <strong>Erscheinungstag</strong> des Inserates.
Ansprechperson und Telefonnummer für Rückfragen bitte mitsenden. Sie erleichtern uns damit das schnelle
Auffinden Ihres Inserates im Empfangsordner. Weiters sollte auch auf dem Auftrag oder Manuskript der Betreff
bzw. Dateiname vermerkt sein. Sollten Sie kein PDF senden, komprimieren Sie bitte Ihre Daten (z. B. ZIP),
um eine sichere Übertragung zu gewährleisten und Übertragungszeit und -kosten zu sparen.</p>
     <div class="tabelle-box">
     
      
      <div class="row head-row hide-small">
        <div class="col-lg-4 text-align-left"></div>
        <div class="col-lg-4 text-align-left">Zeitung</div>
        <div class="col-lg-4 text-align-left">Magazin</div>
      </div>
      <div class="row content-row">
        <div class="col-lg-4 text-align-left strong992px">Auflösung der Graustufen- und Farbbilder</div>
        <div class="col-lg-4 col-6 text-align-left visible-small">ZEITUNG</div>
        <div class="col-lg-4 col-6 text-right-small">Mindestens 200 dpi bei 100 % Bildgröße</div>
        <div class="col-lg-4 col-6 text-align-left visible-small">MAGAZIN</div>
        <div class="col-lg-4 col-6 text-right-small">Mindestens 300 dpi bei 100 % Bildgröße</div>
      </div>
      <div class="row content-row">
        <div class="col-lg-4 text-align-left strong992px">Bitmap</div>
        <div class="col-lg-4 col-6 text-align-left visible-small">ZEITUNG</div>
        <div class="col-lg-4 col-6 text-right-small">Mindestens 600 dpi</div>
        <div class="col-lg-4 col-6 text-align-left visible-small">MAGAZIN</div>
        <div class="col-lg-4 col-6 text-right-small">Mindestens 800 dpi</div>
      </div>
       <div class="row content-row">
        <div class="col-lg-4 text-align-left strong992px">Farbprofil</div>
        <div class="col-lg-4 col-6 text-align-left visible-small">ZEITUNG</div>
        <div class="col-lg-4 col-6 text-right-small">WAN-IFRAnewspaper26v5.icc</div>
        <div class="col-lg-4 col-6 text-align-left visible-small">MAGAZIN</div>
        <div class="col-lg-4 col-6 text-right-small">Innenteil: PSO_LWC_Improved_eci.icc<br>Umschlag (stärkeres Papier)<br>ISOcoated_v2_300_eci.icc</div>
      </div>
      <!--
      <div class="row content-row">
        <div class="col-lg-4 text-align-left strong992px">Linien</div>
        <div class="col-lg-4 col-6 text-align-left visible-small">ZEITUNG</div>
        <div class="col-lg-4 col-6 text-right-small">Positiv min. 0,3 pt; negativ/gerastert min. 0,5 pt; keine Haarlinien</div>
        <div class="col-lg-4 col-6 text-align-left visible-small">MAGAZIN</div>
        <div class="col-lg-4 col-6 text-right-small">Mindestens 0,25 pt = 0,088 mm</div>
      </div>
      -->
      <div class="row content-row">
        <div class="col-lg-4 text-align-left strong992px">Office-Daten</div>
        <div class="col-lg-8 col-12 text-right-small">Word, Excel, Powerpoint müssen in einem DTP-Programm nachgebaut werden. In Office-Dokumenten eingebettete
Logos und Bilder haben oft zu geringe Auflösung für Zeitungs- und Magazindruck bzw. beinhalten die Gefahr von
Farbverfremdung.</div>
      </div>
      <div class="row content-row">
        <div class="col-lg-4 text-align-left strong992px">PDF-Standard</div>
        <div class="col-lg-8 col-12 text-right-small">Datenanlieferung als standardisiertes PDF/X-4<br>
ICC-Profile-Download unter: <a href="https://support.styriamediadesign.at" target="_blank" class="content-link">support.styriamediadesign.at</a></div>
      </div>
    </div>

    
    <div class="row kontakt-box-all">
      <div class="col-lg-6">
        <h2>Anlieferung Raumanzeigen</h2>
        <a href="mailto:meinewerbung@kleinezeitung.at" class="content-link">meinewerbung@kleinezeitung.at</a><br>
        <a href="tel:+433168753781" class="content-link">0316 875-3781</a>
        <div class="kontakt-box margin-t-s">
        <h2>Technische Auskünfte</h2>
        <h3>Styria Media Design GmbH &amp; Co KG</h3>
        <p>Dieter Bem, <a href="tel:+434635800405" class="content-link">0463/58 00-405</a></p>
        <p>Heimo Zmuegg, <a href="tel:+434635800350" class="content-link">0463/58 00-350</a></p>
        <p><a href="https://support.styriamediadesign.at" target="_blank" class="content-link">Styria Media Design - Service</a></p>
        </div>
      </div>
      <div class="col-lg-6">
        <h2>ANLIEFERUNG DIGITALE DATEN</h2>
        <a href="mailto:meinewerbung@kleinezeitung.at" class="content-link">meinewerbung@kleinezeitung.at</a><br>
        <a href="tel:+433168753740" class="content-link">0316 875-3740</a>
        <div class="kontakt-box margin-t-s">
        
        <h3>TECHNISCHE ABWICKLUNG &amp; KAMPAGNENBUCHUNG SOWIE NATIONALE VERMARKTUNG</h3>
        <img src="@/assets/img/img-content/allgemeines/cope-group.svg" alt="Cope" class="img-fluid img-cope" />
        <p>AGB: Für nationale Buchungen gelten die Allgemeinen Geschäftsbedingungen der Content Performance Group GmbH</p>
        <p><a href="https://www.copegroup.com/allgemeine-geschaeftsbedingungen/" target="_blank" class="content-link">Allgemeine Geschäftsbedingungen</a></p>
        </div>
      </div>
    </div>
    <div class="margin-t-s"></div>
    <h2>Technische Daten Digital</h2>
    <p>Spezifikationen für die Werbemittel, im Speziellen
für die Anlieferung von html5-Dateien, finden Sie unter:
<a href="https://www.iab-austria.at/" target="_blank" class="content-link">www.iab-austria.at</a></p>
    <p>Anlieferung: Standardwerbeformen müssen drei Werktage,
alle Sonderwerbeformen oder Unterlagen zur
Gestaltung von Online-Werbeformen müssen
fünf Werktage vor Erscheinen angeliefert werden.
Die Vorlaufzeit für Native-Advertising-Formate beläuft
sich auf mind. Zehn Werktage (ausgenommen Advertorials).</p>
<p>Akzeptierte Werbeformate:<br>
<ul class="content-ul">
  <li>jpg, png mit einer <strong>max. Dateigröße von 70 kb</strong></li>
  <li>gif, html5 mit einer <strong>max. Dateigröße von 150 kb</strong></li>
</ul>
</p>
<p>Weitere Informationen unter
  <a href="https://www.copegroup.com/wp-content/uploads/2021/11/Spezifikationen_COPE-Netzwerk-sd-one-2021-11-11.pdf" target="_blank" class="content-link">COPE Spezifikationen</a>
  </p>



   

  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';


export default defineComponent({
  components: {

  },
});
</script>
